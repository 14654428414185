import { render, staticRenderFns } from "./company-menu.vue?vue&type=template&id=7879b652&scoped=true&"
import script from "./company-menu.vue?vue&type=script&lang=ts&"
export * from "./company-menu.vue?vue&type=script&lang=ts&"
import style0 from "./company-menu.vue?vue&type=style&index=0&id=7879b652&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7879b652",
  null
  
)

export default component.exports