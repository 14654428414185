
import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator'
import { createPopper, Instance, Rect } from '@popperjs/core'
import DomainIcon from '~/components/element/icon/domain.vue'
import { isHTMLObject } from '~/utils/vue-helper'
import SettingsMenu from '~/components/layout/menu/settings-menu.vue'
import SwitchSeller from '~/components/layout/menu/switch-seller.vue'
import DevelopersMenu from '~/components/layout/menu/developers-menu.vue'
import SignoutMenu from '~/components/element/signout.vue'
import AccountMenu from '~/components/layout/menu/account-menu.vue'
import PencilIcon from '~/components/element/icon/pencil.vue'
import PrimaryBtn from '~/components/element/primary-btn.vue'
import mutation from '~/queries/updateTeam.gql'
import { UpdateTeamMutation, UpdateTeamMutationVariables, UserQuery, UserQueryVariables } from '~/types/eldamar'
import { emitError } from '~/utils/nuxt-helper'
import GroupIcon from '~/components/element/icon/group.vue'
import MiniSettingEditBtn from '~/components/element/limitation/mini-setting-edit-btn.vue'
import userQuery from '~/queries/user.gql'
import DownloadIcon from '~/components/element/icon/download-icon.vue'

const sellerStore = namespace('seller')

@Component({
  components: {
    DownloadIcon,
    DomainIcon,
    SettingsMenu,
    SwitchSeller,
    DevelopersMenu,
    SignoutMenu,
    AccountMenu,
    PencilIcon,
    PrimaryBtn,
    GroupIcon,
    MiniSettingEditBtn,
  },
})
export default class extends Vue {
  @Prop({ default: false })
  private readonly collapse!: boolean

  @sellerStore.State
  private readonly sellerName!: string

  @sellerStore.State
  private readonly assignment?: boolean

  @sellerStore.State('teamName')
  private readonly team!: string

  @sellerStore.State
  private readonly teamColor!: number

  @sellerStore.State
  private readonly memberManagement!: boolean

  @sellerStore.Mutation
  private readonly setTeamName!: (s: string) => void

  private isActive = false
  private isTooltipActive = false
  private instance: Instance | null = null
  private tooltip: Instance | null = null
  private isEdit = false
  private inputTeamName = ''

  get showEditButton (): boolean {
    if (this.isEdit) {
      return false
    }
    return true
  }

  get teamName (): string {
    if (!this.team) {
      return 'チーム名未設定'
    }

    return this.team
  }

  get colorClass (): string {
    return `text-team-${this.teamColor}`
  }

  async mounted (): Promise<void> {
    try {
      const result = await this.$apollo.query<UserQuery, UserQueryVariables>({
        query: userQuery,
      })
      if (result?.data.user?.email) {
        this.$store.commit('setUserEmail', result.data.user.email)
      }
    } catch (_e) {}
  }

  private open (): void {
    this.isActive = true
    this.$nextTick(() => {
      const refs = this.$refs['company-menu']
      const ref = Array.isArray(refs) ? refs[0] : refs
      const pops = this.$refs['company-popover']
      const pop = Array.isArray(pops) ? pops[0] : pops
      if (ref && isHTMLObject(ref) && pop && isHTMLObject(pop)) {
        this.instance = createPopper(ref, pop, {
          placement: 'bottom-start',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: ({ reference }: {reference: Rect}): number[] => {
                  return [8, reference.height * -1]
                },
              },
            },
          ],
          onFirstUpdate: () => {
            this.closePopper()
          },
        })
      }
    })
  }

  private close (): void {
    this.isActive = false
    this.isEdit = false
    if (this.instance) {
      this.instance.destroy()
      this.instance = null
    }
  }

  private openPopper (): void {
    if (!this.collapse) {
      return
    }
    if (this.isActive) {
      return
    }
    this.isTooltipActive = true
    this.$nextTick(() => {
      const refs = this.$refs['company-menu']
      const ref = Array.isArray(refs) ? refs[0] : refs
      const pops = this.$refs.tooltip
      const pop = Array.isArray(pops) ? pops[0] : pops
      if (ref && isHTMLObject(ref) && pop && isHTMLObject(pop)) {
        this.tooltip = createPopper(ref, pop, {
          placement: 'right',
        })
      }
    })
  }

  private closePopper (): void {
    this.isTooltipActive = false
    if (this.tooltip) {
      this.tooltip.destroy()
      this.tooltip = null
    }
  }

  private openEditTeam (): void {
    this.isEdit = true
    this.inputTeamName = this.team
  }

  private async updateTeamName (): Promise<void> {
    try {
      const res = await this.$apollo.mutate<UpdateTeamMutation, UpdateTeamMutationVariables>({
        mutation,
        variables: {
          team: {
            name: this.inputTeamName,
          },
        },
      })
      this.setTeamName(res.data?.updateTeam?.name || '')
    } catch (e) {
      emitError(this.$nuxt, e)
    } finally {
      this.inputTeamName = ''
      this.isEdit = false
    }
  }
}
